import {
  AUTH_COOKIE_NAME,
  REFRESH_COOKIE_NAME,
} from '@paladise/config/constants'
import { logout } from 'features/auth/actions/logout'
import { NEED_LOG_OUT_ERROR_CODES } from 'features/auth/constants'
import { refreshTokenAndSetCookie } from 'features/auth/utils/refresh-token'
import Cookies from 'js-cookie'
import { FetchOptions, ofetch } from 'ofetch'
import { env } from 'store/server-context/static'
import { Fetch } from './type'

export const CLIENT_API = {
  get ASGARD() {
    return env.API_ASGARD
  },
  get USER() {
    return env.API_USER
  },
  get MEDIA() {
    return env.API_MEDIA
  },
  get SESSION() {
    return env.API_SESSION
  },
  get HERMES() {
    return env.API_HERMES
  },
  get CHAT() {
    return env.API_CHAT
  },
  get REPORT() {
    return env.API_REPORT
  },
  get GW() {
    return env.API_GW
  },
}

const injectToken = ({ options }: { options: FetchOptions }) => {
  const headers = new Headers(options.headers)
  if (headers.has('Authorization')) {
    return
  }
  const token = Cookies.get(AUTH_COOKIE_NAME)
  if (token) {
    headers.set('Authorization', `Bearer ${token}`)
    options.headers = headers
  }
}

export const clientFetch = ofetch.create({
  async onRequest({ options }) {
    injectToken({ options })
  },
  async onResponseError({ request, response, options }) {
    const errorCode = response._data.code
    if (NEED_LOG_OUT_ERROR_CODES.includes(errorCode)) {
      await logout()

      return
    }

    if (response.status === 401) {
      const token = await refreshTokenAndSetCookie(
        Cookies,
        Cookies.get(REFRESH_COOKIE_NAME) || '',
      )
      if (!token) {
        await logout()
      } else {
        options.headers = new Headers({
          Authorization: `Bearer ${token}`,
        })
        options.retry = 1
        options.retryStatusCodes = [401]
      }
    }

    console.log(
      `[client fetch error]: ${request} ${response._data?.cause || response._data?.msg || ''}`,
    )
  },
}) as Fetch

clientFetch.API = CLIENT_API
