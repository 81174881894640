import {
  IAuthRes,
  ILoginReq,
  ILoginRes,
  AuthSchemaType,
} from 'features/auth/types'
import type { Device } from 'lib/device/type'
import type { Fetch } from 'lib/fetch/type'
import { ApiCode, type IRespV2 } from 'type/common'

export const fastLoginV2 = async (
  fetcher: Fetch,
  device: Device,
  country: string,
): Promise<ILoginRes> => {
  const res: IRespV2<ILoginRes> = await fetcher(
    fetcher.API.GW + `/v1/user/account/fast_login`,
    {
      method: 'POST',
      body: {
        device,
        info: {
          country,
        },
      },
    },
  )

  return res.data
}

export const refreshToken = async (
  fetcher: Fetch,
  refresh_token: string,
): Promise<IAuthRes> => {
  const res: IRespV2<IAuthRes> = await fetcher(
    fetcher.API.GW + '/v1/auth/refresh',
    {
      method: 'POST',
      body: {
        refresh_token,
        account_type: 'personal',
      },
    },
  )
  return res.data
}

export const login = async (
  fetcher: Fetch,
  req: ILoginReq,
): Promise<ILoginRes | null> => {
  try {
    const res: IRespV2<ILoginRes> = await fetcher(
      fetcher.API.GW + `/v1/user/account/login`,
      {
        method: 'POST',
        body: req,
      },
    )

    return res.data
  } catch (error) {
    console.log('🚀 ~login error:', error)
    return null
  }
}

export const bindThirdParty = async (
  fetcher: Fetch,
  req: {
    account_platform: string
    account_platform_access_token: string
    account_uid: string
    account_email: string
  },
): Promise<boolean> => {
  const res: IRespV2<string> = await fetcher(
    fetcher.API.USER + `/web/v1/user/account/bind_third_party_account`,
    {
      method: 'POST',
      body: JSON.stringify(req),
    },
  )

  return res.code === ApiCode.success
}

export const bindUser = async (
  fetcher: Fetch,
  req: AuthSchemaType,
): Promise<boolean> => {
  const res: IRespV2<string> = await fetcher(
    fetcher.API.USER + `/web/v1/user/account/bind`,
    {
      method: 'PATCH',
      body: JSON.stringify(req),
    },
  )

  return res.code === ApiCode.success
}
