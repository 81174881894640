export const ThirdParty = {
  bind: {
    google: 'binding-google',
    facebook: 'binding-facebook',
  },
  auth: {
    google: 'google',
    facebook: 'facebook',
  },
} as const

export enum AuthDialogEnum {
  Login = 'login',
  SignUp = 'signUp',
}

// https://playsee.atlassian.net/wiki/spaces/AI/pages/495976458/AI+Gateway
export const NEED_LOG_OUT_ERROR_CODES = [
  751103002, 751103003, 751103004, 751103005, 751103006,
]
