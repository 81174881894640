'use client'

import { CDN_URL } from '@paladise/config/constants'
import {
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@paladise/ui/components/ui/dialog'
import { useTranslations } from 'next-intl'

const QrcodeModal = () => {
  const t = useTranslations()

  return (
    <DialogContent className="flex flex-col items-center gap-6 bg-transparent shadow-none outline-none">
      <DialogDescription>
        <img
          src={CDN_URL + '/human/images/qr_code_v1_2.svg'}
          alt="QRCode"
          className="min768:size-[280px] size-[210px]"
          width="96"
          height="96"
        />
      </DialogDescription>

      <DialogTitle className="text-label-still-l1 text-center text-lg font-semibold">
        {t('common.download_the_app_and_start_interacting!')}
      </DialogTitle>
    </DialogContent>
  )
}

export default QrcodeModal
