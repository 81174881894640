import {
  AUTH_COOKIE_NAME,
  REFRESH_COOKIE_NAME,
} from '@paladise/config/constants'
import { SERVER_GW } from '@paladise/config/env'
import { refreshToken } from 'api/auth'
import { Fetch } from 'lib/fetch/type'
import { ofetch } from 'ofetch'
import { CookieSetter, IAuthRes } from '../types'

export const handleRefreshToken = async (
  tempRefreshToken: string,
): Promise<IAuthRes | null> => {
  try {
    // create a new fetch instance in order to not trigger serverFetch onResponseError
    const f = ofetch.create({})
    // @ts-ignore
    f.API = {
      GW: SERVER_GW,
    }
    const res = await refreshToken(f as Fetch, tempRefreshToken)
    return res
  } catch (error) {
    console.log('error refresh token: ', error)
    return null
  }
}

export const refreshTokenAndSetCookie = async (
  cookieStore: { set: CookieSetter },
  refreshToken: string,
): Promise<string | null> => {
  const res = await handleRefreshToken(refreshToken)

  if (!res) {
    return null
  }

  cookieStore.set(AUTH_COOKIE_NAME, res.access_token, {
    httpOnly: false,
    expires: new Date(res.access_token_expire_at),
  })
  cookieStore.set(REFRESH_COOKIE_NAME, res.refresh_token, {
    httpOnly: false,
    expires: new Date(res.refresh_token_expire_at),
  })

  return res.access_token
}
